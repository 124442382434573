// @ts-ignore
import Worker from './worker/phs-worker';
import { Workermessage } from './interfaces/workermessage';
import { createCustomEvent } from './utils/utils';

const worker = new Worker();

export const blobs: string[] = [];
export const errors: string[] = [];

//export const encodeImgs = (files: FileList) => worker.postMessage({ message: 'files', files });

export const encodeImg = (file: File) => worker.postMessage({ message: 'file', file });

export const clearQueue = () => blobs.length = 0;

worker.onmessage = ({ data }: MessageEvent) => {
  const { blob, error: workerError, isEnded }: Workermessage = data;

  if (isEnded && blob) {
    blobs.push(blob);
    createCustomEvent('imgsEncodingEnded', document);
  }

  if (isEnded && workerError) {
    errors.push(workerError);
    createCustomEvent('imgsEncodingError', document);
  }
};
