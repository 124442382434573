/**
 * Function to create a custom Event
 * @param evt - {string} - The name of the custom event
 * @param target - {Window|Document} - The ref to the element who have to dispatch the event
 */
export const createCustomEvent = (evt: string, target: Window | Document = window): void => {
  const event: Event = document.createEvent('Event');
  event.initEvent(`${evt}`, true, true);
  target.dispatchEvent(event);
};
